import React from "react"
import { FaPhone, FaWhatsapp } from "react-icons/fa"

const trim = s => s.replace(/[-\(\) ]/g, "")
const separateNumber = s => s.match(/(\(.*\))(.*)/)

const WhatsApp = ({ phone, className }) => {
  const separated = separateNumber(phone.number)
  return (
    <a
      href={`https://wa.me/${trim(phone.number).replace(/8/, "7")}`}
      className={`${className} contact-link whats-app`}
      onClick={() => {
        window.ym(41154959, "reachGoal", phone.reachGoal)
        return true
      }}
    >
      <div className="phone-part-one">
        <FaWhatsapp
          style={{
            fontSize: "0.9rem",
            paddingRight: "0.1rem",
            color: "#0d0",
            marginRight: "5px"
          }}
        />
        <span className="first-num">8</span>
        <span className="second-num">{separated[1]}</span>
      </div>
      <div className="phone-part-two">
        {" "}
        <span className="third-num">{separated[2]}</span>
      </div>
    </a>
  )
}

const SeparatedPhone = ({ separated }) => (
  <>
    <div className="phone-part-one">
      <span className="first-num">8</span>
      <span className="second-num">{separated[1]}</span>
    </div>
    <div className="phone-part-two">
      {" "}
      <span className="third-num">{separated[2]}</span>
    </div>
  </>
)

const Phone = ({ phone, className }) => {
  const separated = separateNumber(phone.number)
  return (
    <a
      href={`tel:${trim(phone.number)}`}
      className={`${className} contact-link phone`}
      onClick={() => {
        window.ym(41154959, "reachGoal", phone.reachGoal)
        return true
      }}
    >
      <div className="phone-part-one">
        <FaPhone
          style={{
            fontSize: "0.9rem",
            paddingRight: "0.1rem",
            marginRight: "5px"
          }}
        />
        <span className="first-num">8</span>
        <span className="second-num">{separated[1]}</span>
      </div>
      <div className="phone-part-two">
        {" "}
        <span className="third-num">{separated[2]}</span>
      </div>
    </a>
  )
}

const MobilePhone = ({ phone, className }) => (
  <a
    href={`tel:${trim(phone.number)}`}
    className={`${className} contact-link mobile`}
    onClick={() => {
      window.ym(41154959, "reachGoal", phone.reachGoal)
      return true
    }}
  >
    <FaPhone
      style={{
        fontSize: "0.7rem",
        paddingRight: "0.1rem",
      }}
    />
    <SeparatedPhone separated={separateNumber(phone.number)} />
  </a>
)

export default ({ phones, className }) => {
  return phones.map((phone, index) => {
    if (phone.type === "whatsapp") {
      return <WhatsApp phone={phone} className={className} key={index} />
    } else if (phone.type === "phone") {
      return <Phone phone={phone} className={className} key={index} />
    } else if (phone.type === "mobile-phone") {
      return <MobilePhone phone={phone} className={className} key={index} />
    }
  })
}
