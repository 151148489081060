import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PriceMenu from "../components/priceMenu"

import {Col, Row, Container} from "react-bootstrap"

import { graphql } from 'gatsby'

const Page = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Прайс на прием черных и цветных металлов"
      description="Цены на прием черных и цветных металлов в Алтайском крае и близлежащих регионах."
    />
    <Container>
      <div className="bg-white p-2">
        <Row className="justify-content-md-center">
          <Col lg="12" className="p-1">
            <h1 className="py-3">Прайс «АлМет»</h1>
            <p>
              Цены на прием черного и цветного металла в Барнауле, Новоалтайске и Рубцовске.
          </p>
            <p>
              Цены на стальной кусковой лом, стальной негабаритный лом, лом чугуна промышленного, стружка, автомобили, и др.
          </p>
            <p>
              Цены на прием меди, латуни, алюминия, аккумуляторов, свинца, нержавеющей стали, нихромов и никельсодержащих сплавов, цинка, магния, твердых сплавов, победита, быстрорежущей стали, титана, электродвигателей.
          </p>
          </Col>
          <Col lg="12" style={{border: "1px solid #ddd"}} className="py-4">
            <PriceMenu location={props.location} lg="3" contacts={props.data.site.siteMetadata.contacts} />
          </Col>
        </Row>
      </div>
    </Container>

  </Layout>
)

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        contacts {
          address
          city
        }
      }
    }
  }
`

