import React from "react"
import Layout from "./layout"
import SEO from "./seo"
import { Row, Col, Container } from "react-bootstrap"
import Price from "./price"
import PriceMenu from "./priceMenu"
import Phones from "./phones"
import { Link } from "gatsby"

const Page = props => (
  <Layout location={props.location}>
    <SEO title={props.title} description={props.description} />
    <Container>
      <div className="bg-white">
        <Row>
          <Col lg="9" className="p-3">
            <h1 className="my-4">{props.title}</h1>
            {props.body ? (
              <div dangerouslySetInnerHTML={{ __html: props.body }} />
            ) : (
              ""
            )}
            <div className="pl-2">
              <Link to="contacts" className="btn p-0">
                {props.contacts.city}, {props.contacts.address}
              </Link>{" "}
              {` `}
              <Phones phones={props.contacts.phones} className="btn p-0 pr-2" />
            </div>
            <div className="check">
         
            {props.data.strapiHtmlBlock ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.strapiHtmlBlock.body,
                }}
              />
            ) : (
              ""
            )}
            </div>
           

            <p>НАЛИЧИЕ ПАСПОРТА ОБЯЗАТЕЛЬНО!</p>
          </Col>
          <Col lg="3" className="py-5 bg-light">
            <PriceMenu location={props.location} />
          </Col>
        </Row>
      </div>
    </Container>
  </Layout>
)

export default Page
